import React from 'react'
import styled from 'styled-components'

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
`

const Text = styled.div`
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
  }
  text-align: center;
  margin-bottom: 0.3rem;
  padding: 0;
`

const Please = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 19px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const Coment = () => {
  return (
    <TextContainer>
      <Text>가천대학교 등록금 집행 투명성 확보 및</Text>
      <Text>학생 기본 권리 보장을 위한</Text>
      <Text>온라인 서명운동을 진행합니다.</Text>
      <Please>여러분들의 적극적인 관심과 지지를 부탁드립니다.</Please>
    </TextContainer>
  )
}

export default Coment
