import { createGlobalStyle } from 'styled-components'
import PretendardThin from './Pretendard-Thin.woff2'
import PretendardExtraLight from './Pretendard-ExtraLight.woff2'
import PretendardLight from './Pretendard-Light.woff2'
import PretendardRegular from './Pretendard-Regular.woff2'
import PretendardMedium from './Pretendard-Medium.woff2'
import PretendardBold from './Pretendard-Bold.woff2'
import PretendardExtraBold from './Pretendard-ExtraBold.woff2'
import PretendardBlack from './Pretendard-Black.woff2'

const GlobalFont = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    src: url(${PretendardThin}) format('woff2'),
  }
  
  @font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    src: url(${PretendardExtraLight}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    src: url(${PretendardLight}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    src: url(${PretendardRegular}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    src: url(${PretendardMedium}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    src: url(${PretendardBold}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    src: url(${PretendardExtraBold}) format('woff2'),
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    src: url(${PretendardBlack}) format('woff2'),
  }
`

export default GlobalFont
