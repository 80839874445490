import React from 'react'
import styled from 'styled-components'
import {
  STATEMENT_DOC1,
  STATEMENT_DOC1_IMPORTANT,
  STATEMENT_DOC2,
  REQUIREMENT,
} from '../../constants/statement'

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 61px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 30px;
  }
  @media (min-width: 1024px) {
    font-size: 40px;
  }
  margin-bottom: 10px;
`

const Date = styled.div`
  font-size: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
  margin-bottom: 26px;
  margin-top: 16px;
`

const Text = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fontSize', 'fontWeight'].includes(prop),
})`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  width: 100%;
  display: flex;
  justify-content: left;
  line-height: 1.7;
  font-weight: ${({ fontWeight }) => fontWeight || 'lighter'};
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize + 8}px` : '20px')};
  }
  @media (min-width: 1024px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize + 16}px` : '28px')};
  }
  text-align: justify;
  margin-bottom: 0.3rem;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 10px;
`

const RequiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 30px 0;
`

const Required = styled.div`
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 31px;
  }
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
`

const Body = () => {
  return (
    <Container>
      <Title>우리의 등록금은 어디로 흘러들어가며,</Title>
      <Title>대학 본부의 존재 이유는 무엇인가?</Title>
      <Date>2024. 11. 24</Date>
      <Text>{STATEMENT_DOC1}</Text>
      <Text fontSize={14} fontWeight="300">
        {STATEMENT_DOC1_IMPORTANT}
      </Text>
      <RequiredContainer>
        {REQUIREMENT.map((req, index) => (
          <Required key={index}>{req}</Required>
        ))}
      </RequiredContainer>
      <Text>{STATEMENT_DOC2}</Text>
    </Container>
  )
}

export default Body
