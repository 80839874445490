import styled from 'styled-components'

const LogoContainer = styled.div`
  width: 98px;
  height: auto;
  overflow: hidden;
  margin-bottom: 10px;
`

const CheongLogoImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`

const Title = styled.div`
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 26px;
  }
  @media (min-width: 1024px) {
    font-size: 32px;
  }
  text-align: center;
  margin-bottom: 0.3rem;
  padding: 0;
`

const Title2 = styled.div`
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
  }
  text-align: center;
  margin-bottom: 0.1rem;
  padding: 0;
`

const Text = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 0.3rem;
  padding: 0;
`

const People = styled.div`
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
`

export {
  Container,
  TextContainer,
  Title,
  Title2,
  Text,
  LogoContainer,
  CheongLogoImg,
  People,
}
