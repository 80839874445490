export const STATEMENT_DOC1 = `안녕하십니까, 가천대학교 제40대 총학생회장 조영학, 부총학생회장 송지원입니다. 먼저, 좋지 않은 소식으로 찾아뵙게 되어 학우분들께 진심 어린 사과를 드립니다. 본 성명문을 통해 가천대학교의 문제를 밝히고, 대학 본부에 대한 규탄과 함께 강력한 쇄신을 요구합니다.

축제 관련 입장을 뒤늦게 밝히는 이유는 학생복지처장 및 학생지원팀장에게 ‘축제 전 본부와 전면전을 할 경우 축제에 대한 대학 본부 모든 부서의 협조를 끊겠다.’는 압박이 있었기 때문입니다. 대학 본부의 모든 부서의 협조가 끊기는 경우 학우분들이 준비했던 공연 및 주점, 부스 등 모든 것들이 물거품이 될뿐더러 학우분들의 소중한 가을축제 진행이 아예 불가능했기에, 뒤늦게 문제를 제기하는 점에 대해 학우분들의 양해 부탁드립니다.

저희 총학생회 ‘청[靑]’은 학우분들의 등록금으로 이루어지는 행사 중 가장 큰 비율을 차지하는 축제가 효율적이고 공정한 절차로 사용되어야 한다는 생각에 대행사 선정이 더 투명하고 숙의 과정을 거쳐야 한다고 판단하였습니다. 또한, 가천대학교의 축제 대행사는 항상 한군데의 대행사에서 진행되었다는 의혹이 내외부에서 지속적으로 제기되는 상황이었습니다. 이에 따라 근 몇 년간의 대행사 선정 과정을 추적한 결과, 대행사의 사명은 모두 달랐으나 실제 책임자는 1명이라는 기이한 상황을 파악하였습니다. 그 책임자는 본교의 졸업생이며 축제를 담당하고 있는 모 교직원과도 가까운 사이임을 함께 파악할 수 있었는데, 이를 통해 그동안 제기되었던 유착관계에 대한 의혹에 더욱 주목할 수밖에 없었습니다.

총학생회는 위와 같은 부적절한 관계를 밝혀내고 이것이 사실로 밝혀진다면 이 관계를 끊어내고자 축제 담당 부서인 학생복지처의 처장과 팀장을 찾아가 '투명성'을 끊임없이 요청하고 설득한 끝에 변화를 약속받았습니다. 그러나 축제가 연기되자 대학 본부에서는 태도를 크게 바꿔 다시금 미온적인 태도를 보였습니다. 더해, 저희 총학생회는 유착이 의심되는 자의 조사를 요구했으나 내부적으로 조치할 것이라며 일축하였고, 계속된 총학생회장의 요구에 도리어 ‘명예훼손으로 고소하겠다.’라는 협박을 하였습니다. 또한, 이 과정에서 졸업생 선배들의 회유와 압박 역시 있었습니다. 1년 중 가장 큰 행사인 축제까지 망쳐가면서 대학 본부에서 지키고 싶었던 가치는 무엇입니까?

그뿐만 아니라 지금껏 대학 본부는 학생자치기구의 ‘자치성’을 무시했고, 특히 특정 교직원은 학생을 대상으로 폭언에 폭행까지 서슴지 않았습니다. 학생자치기구 활동을 위해서는 대학본부의 협조를 받아야 하는 것은 행정으로 당연한 절차일 수 있습니다. 그러나 대학본부는 학생자치기구의 일거수일투족을 ‘허락’하려고 하며 협조를 받기 위한 세부안을 들고 가면 교직원들은 행사 기획에 개입하고, 방향성 수정을 강요하여 행사 전반을 학우들의 요구가 아닌, 본인들의 입맛에 맞추려 하고 있습니다. 또한 꾸준한 의혹이 있던 교직원 A는 지속적으로 학생 대표들에게 폭언을 해온 사실이 자명하며, 특히 올해는 일부 학생에게 실제 폭행을 저지르기까지 했습니다. 놀랍게도 이 내용을 모두 대학 본부에서는 파악하고 있으며, 총학생회장은 이에 대해 강하게 항의하며 해당 직원에 대한 보직해임 및 징계를 요구했으나 총학생회장이 직원 인사에 개입하는 월권을 행사한다는 프레임을 씌우며 일체 받아들여진 사실이 없습니다. 제가 본부에 요구한 것은 문제가 있는 사람을 명확히 조사하고, 학생에게 피해를 준 사실이 있다면 이에 대해 법적 근거에 따라 명확히 대처해달라는 요구였습니다. 학내 폭행이 발생했다면, 가천대학교의 구성원 누구든 요구할 수 있는 기본 권리입니다. 총학생회장은 학우분들의 대리인이자 대표자로서 학생의 기본 권리를 지키려고 하였으나, 이를 ‘직원 인사 개입 월권’이라는 프레임으로 무마하려고 한 것은 가천대학교의 구성원의 기본 권리를 무시하는 것과 같습니다.

이에 더해 대학 본부는 각급 학생회의 자율적인 활동을 통제하고자 하였습니다. 가천대학교가 내세우는 가치 중 하나는 ‘학생이 왕이다.’입니다. 대학본부는 대외적으로 학생들을 위한 학교로 포장하고 있는데, 실질적인 학우분들의 증언은 이와 정반대에 위치해 있습니다. 금번 가을 축제 역시 학우들의 알 권리와 집행의 투명성을 위하여 입찰이 무산된 상황을 상세히 밝히고자 했으나 대학 본부는 ‘어차피 축제 끝나면 다 괜찮아진다.’, ‘협의 없는 안내를 강행할 경우 축제를 무산시키겠다’라고 협박하였습니다. 이는 학생을 통제의 대상으로 생각하는 대학 본부의 본심을 대변하는 일례입니다.

그동안 총학생회가 요구한 것은 간단합니다. 투명성, 학생의 기본 권리 보장 두 가지입니다.
`

export const STATEMENT_DOC1_IMPORTANT = `이에 본 성명문을 통해 대학 본부에 다시 한번 요구합니다.`

export const REQUIREMENT = [
  `첫째,
학생을 포함한 가천대학교 구성원의
기본 권리를 보장하라.`,
  `둘째,
집행의 투명성을 위하여 근 7년간 축제 대행사
선정 과정 및 세부 결산, 대행사의 담당자와 관련된 감사위원회를 개최하라.`,
  `셋째,
대행사와 대학 본부 간의 관계를 면밀히 조사하고,
문제가 있는 자는 징계하라.`,
  `넷째,
학생을 '통제'의 대상으로 생각하지 말라.`,
  `다섯째,
학생 자치기구의 '자치성'을 존중하고 학생의
권리 보장을 위해 활동할 수 있는 환경을 보장하라.`,
]

export const STATEMENT_DOC2 = `만약, 대학 본부가 저희의 요구안에 응답하지 않는다면, 2만 5천 가천인을 무시하는 행위입니다. 본 요구안은 일회성이 아님을 유념하여 대학 본부의 명쾌한 응답을 바랍니다.

총학생회는 본 성명문을 통해 문제를 제기하면서도 가천대학교의 명예를 위해 의혹의 대부분이 사실이 아니길 바라고 있습니다. 그러나, 사실임에도 조사하지 않는 것은 더 큰 불명예라고 판단되어 위와 같은 문제 제기를 개진하였습니다. 부디, 우리의 의혹이 기우였음을 증명하여 주시길 바랍니다.

2만 5천의 가천인 여러분.

임기 초 취임사를 통해 ‘학생회의 존재 가치를 증명하겠다.’라고 학우분들께 약속드렸습니다. 그동안 내부적으로 학우분들의 권리 보장을 위해, 학생 사회가 올바른 방향으로 나아갈 수 있도록 최선을 다했으며 이제는 이 사실을 알림으로써 학우분들께 도움을 요청합니다. 많은 관심 부탁드립니다.

총학생회의 힘은 학우분들의 관심과 지지로부터 나옵니다. 이를 기점으로 저희 총학생회 ‘청[靑]’은 남은 임기 동안 2만 5천 가천인의 권리 보장을 위해, 학생 사회의 발전을 위해, 가천대학교에 정의가 뿌리내리기 위해 끝까지 치열하게 투쟁하겠습니다. 감사합니다.`
