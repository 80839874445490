import React from 'react'
import styled from 'styled-components'
import Contact from './Contact'
import Maker from './Maker'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 100px;
`

const Footer = () => {
  return (
    <Container>
      <Contact />
      <Maker />
    </Container>
  )
}

export default Footer
