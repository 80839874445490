import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../common/Button'
import ShareModal from '../modals/ShareModal'
import FormModal from '../modals/FormModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @media (min-width: 768px) {
    margin-top: 3rem;
  }
  @media (min-width: 1024px) {
    margin-top: 4rem;
  }
  gap: 1rem;
`

const Buttons = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isShareOpen, setIsShareOpen] = useState(false)

  return (
    <>
      <Container>
        <Button
          backgroundColor="#004E96"
          fontSize={18}
          onClick={() => setIsFormOpen(true)}
        >
          서명하기
        </Button>
        <Button
          backgroundColor="#73767A"
          fontSize={18}
          onClick={() => setIsShareOpen(true)}
        >
          공유하기
        </Button>
      </Container>
      <FormModal isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
      <ShareModal isOpen={isShareOpen} onClose={() => setIsShareOpen(false)} />
    </>
  )
}

export default Buttons
