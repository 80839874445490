import React, { useState } from 'react'
import styled from 'styled-components'
import { FAQ_LIST } from '../../constants/faq'
import DownDropArrow from '../../assets/icons/DownDropArrow.svg'
import UpDropArrow from '../../assets/icons/UpDropArrow.svg'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 100px;
`

const Title = styled.div`
  font-size: 16px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 31px;
  }
  text-align: center;
  padding: 0;
  margin-bottom: 20px;
`

const FaqContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`

const FaqItem = styled.div`
  width: 100%;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
  margin: 10px 0;
`

const FaqQuestion = styled.div`
  background-color: #031525;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
`

const FaqAnswer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})`
  padding: ${(props) => (props.isOpen ? '15px' : '0')};
  margin: ${(props) => (props.isOpen ? '20px 0' : '0')};
  color: white;
  line-height: 2;
  max-height: ${(props) => (props.isOpen ? '800px' : '0')};
  overflow: hidden;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease,
    padding 0.3s ease,
    margin 0.3s ease;
  white-space: pre-wrap; /* 개행을 반영 */
`

const Icon = styled.img`
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease;
`

const FAQ = () => {
  const [openItems, setOpenItems] = useState({})

  const toggleAnswer = (index) => {
    setOpenItems((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  return (
    <Container id="faqsummary">
      <Title>FAQ & 요약</Title>
      <FaqContainer>
        {FAQ_LIST.map((faq, index) => (
          <FaqItem key={index}>
            <FaqQuestion onClick={() => toggleAnswer(index)}>
              {faq.question}
              <Icon
                src={openItems[index] ? UpDropArrow : DownDropArrow}
                alt={openItems[index] ? 'Collapse' : 'Expand'}
              />
            </FaqQuestion>
            <FaqAnswer
              isOpen={openItems[index] || false}
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            />
          </FaqItem>
        ))}
      </FaqContainer>
    </Container>
  )
}

export default FAQ
