import React from 'react'
import Coment from './Coment'
import Count from './Count'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  @media (min-width: 1024px) {
    height: 120vh;
    margin-bottom: 5rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TotalSign = () => {
  return (
    <Container>
      <Coment />
      <Count />
    </Container>
  )
}

export default TotalSign
