import React, { useState, useRef, useEffect } from 'react'
import DepartmentDropDown from '../../assets/icons/DepartmentDropDown.svg'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`

const DropdownButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'selected',
})`
  width: 100%;
  padding: 16px;
  border: 1px solid #73767a;
  border-radius: 4px;
  background: white;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ selected }) => (selected ? 'black' : '#73767a')};
  border-color: ${({ selected }) => (selected ? 'black' : '#73767a')};
  font-size: 16px;

  &:hover {
    border-color: #000000;
  }
`

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow-y: auto;
  background: white;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  font-size: 16px;
`

const DropdownItem = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isSelected',
})`
  padding: 12px 16px;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? 'lightgray' : 'none')};

  &:hover {
    background: #d0dfed;
  }
`

export default function Dropdown({
  options,
  value,
  onChange,
  placeholder = '학과',
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value || '')
  const dropdownRef = useRef(null)
  const listRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (isOpen && selectedOption) {
      const selectedItem = listRef.current?.querySelector(
        `[data-value="${selectedOption}"]`
      )
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, [isOpen, selectedOption])

  const sortedOptions = options.sort((a, b) => a.localeCompare(b))

  const handleItemClick = (option) => {
    if (option === selectedOption) {
      setSelectedOption('')
      onChange({ target: { name: 'department', value: '' } })
    } else {
      setSelectedOption(option)
      onChange({ target: { name: 'department', value: option } })
    }
    setIsOpen(false)
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        type="button"
        selected={selectedOption}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption || placeholder}</span>
        <img src={DepartmentDropDown} alt="dropdown" />
      </DropdownButton>
      {isOpen && (
        <DropdownList ref={listRef}>
          {sortedOptions.map((option, index) => (
            <DropdownItem
              key={index}
              data-value={option}
              isSelected={option === selectedOption}
              onClick={() => handleItemClick(option)}
            >
              {option}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}
