import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`

const Text = styled.div`
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: 'center';
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
  }
  text-align: justify;
  margin-bottom: 0.3rem;
  padding: 0;
  margin: 20px 0;
`

const PeopleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px;
  border-bottom: 1px solid #ffffff;
`

const People = styled.div`
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: right;
  line-height: 1.5;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
  }
  text-align: justify;
  margin-bottom: 0.3rem;
  padding: 0;
`

const Footer = () => {
  return (
    <Container>
      <Text>2024. 11. 24</Text>
      <PeopleContainer>
        <People>가천대학교 제 40대 총학생회장 조영학</People>
        <People>가천대학교 제 40대 부총학생회장 송지원</People>
      </PeopleContainer>
    </Container>
  )
}

export default Footer
