import React, { useState, useEffect } from 'react'
import { getCurrentTime } from '../../utils/time'
import {
  Container,
  TextContainer,
  Title,
  Title2,
  Text,
  LogoContainer,
  CheongLogoImg,
  People,
} from '../../styles/Main/ContentStyles'
import CheongLogo from '../../assets/icons/CheongLogo.svg'
import { CountApi } from '../../apis/countAPI'

const Content = () => {
  const [count, setCount] = useState(0) // 현재 카운트
  const [targetCount, setTargetCount] = useState(0) // 목표 카운트
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  useEffect(() => {
    CountApi().then((res) => setTargetCount(res.count))

    const interval = setInterval(() => {
      CountApi().then((res) => setTargetCount(res.count))
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (targetCount === 0) return

    const step = Math.ceil(targetCount / 100)
    let interval

    const animateCount = () => {
      interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= targetCount) {
            clearInterval(interval)
            return targetCount
          }
          return prevCount + step
        })
      }, 150)
    }

    animateCount()

    return () => clearInterval(interval)
  }, [targetCount])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Container>
      <LogoContainer>
        <CheongLogoImg src={CheongLogo} alt="가천대학교 로고" />
      </LogoContainer>
      <Title>가천대학교 등록금 집행 투명성 확보 및</Title>
      <Title>학생 기본 권리 보장을 위한</Title>
      <Title2> 온라인 서명 운동</Title2>
      <TextContainer>
        <Text>재학생 및 휴학/졸업생 대상</Text>
        <Text>{currentTime} 기준</Text>
        <People>{new Intl.NumberFormat().format(count)}명</People>
        <Title2>참여 완료</Title2>
      </TextContainer>
    </Container>
  )
}

export default Content
