import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BlurCircle from '../../assets/icons/BlurCircle.svg'
import { CountApi } from '../../apis/countAPI'

const CircularGradient = styled.div`
  width: 390px;
  height: 354px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 88px;

  @media (min-width: 768px) {
    width: 500px;
    height: 454px;
  }
  @media (min-width: 1024px) {
    width: 600px;
    height: 554px;
  }
`

const BlurCircleImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: -1;
`

const AllTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`

const TotalSign = styled.div`
  font-size: 16px;
  color: #004e96;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 33px;
  }
  text-align: center;
  padding: 0;
  margin: 0;
`

const Text = styled.div`
  font-size: 24px;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 29px;
  }
  @media (min-width: 1024px) {
    font-size: 39px;
  }
  text-align: center;
  padding: 0;
  margin: 0;
`

const Comment = styled.div`
  font-size: 8px;
  width: 80%;
  color: #73767a;
  text-align: right;
  @media (min-width: 768px) {
    font-size: 13px;
  }
  @media (min-width: 1024px) {
    font-size: 18px;
  }
  text-align: right;
  padding: 0;
  margin: 0;
`

const Count = () => {
  const [count, setCount] = useState(0)
  const [targetCount, setTargetCount] = useState(0)

  useEffect(() => {
    CountApi().then((res) => setTargetCount(res.count))

    const interval = setInterval(() => {
      CountApi().then((res) => setTargetCount(res.count))
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (targetCount === 0) return

    const step = Math.ceil(targetCount / 100)
    let interval

    const animateCount = () => {
      interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= targetCount) {
            clearInterval(interval)
            return targetCount
          }
          return prevCount + step
        })
      }, 100)
    }

    animateCount()

    return () => clearInterval(interval)
  }, [targetCount])

  return (
    <CircularGradient>
      <BlurCircleImg src={BlurCircle} alt="BlurCircle" />
      <AllTextContainer>
        <TextContainer>
          <TotalSign>총 서명</TotalSign>
          <TotalSign>{new Intl.NumberFormat().format(count)}</TotalSign>
        </TextContainer>
        <TextContainer>
          <Text>온라인 서명</Text>
          <Text>{new Intl.NumberFormat().format(count)}</Text>
        </TextContainer>
        <TextContainer>
          <Text>수기 서명</Text>
          <Text>0</Text>
        </TextContainer>
        <Comment>*수기 서명은 매일 오전 10시에 갱신됩니다.</Comment>
      </AllTextContainer>
    </CircularGradient>
  )
}

export default Count
