import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 100px;
  margin-bottom: 50px;
`

const Text = styled.div`
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const TitleContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: top;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  gap: 13px;
`

const ContactTextContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const ContactText = styled.a`
  color: #ffffff;

  &:visited {
    text-decoration: none;
    color: #ffffff;
  }
`

const Maker = () => {
  return (
    <Container>
      <TitleContainer>
        <Text>문의</Text>
      </TitleContainer>
      <ContentContainer>
        <ContactContainer>
          <Text>노정완 컴퓨터공학과</Text>
          <Text>조혜원</Text>
          <Text>조예진</Text>
        </ContactContainer>
        <ContactContainer>
          <ContactTextContainer>
            | &nbsp;
            <ContactText
              href="mailto:jeongwan.noh@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              jeongwan.noh@gmail.com
            </ContactText>
          </ContactTextContainer>
        </ContactContainer>
      </ContentContainer>
    </Container>
  )
}

export default Maker
