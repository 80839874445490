import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('/GachonBG.png'),
    linear-gradient(
      to bottom,
      rgba(1, 5, 10, 0.98) 0%,
      rgba(1, 5, 10, 0.9) 57%,
      rgba(7, 19, 33, 0.9) 100%
    );
  background-size:
    cover,
    100% 100%;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-blend-mode: multiply;
  position: relative;
`

const BackgroundImg = ({ children }) => <Container>{children}</Container>

BackgroundImg.propTypes = {
  children: PropTypes.node,
}

export default BackgroundImg
