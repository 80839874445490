import styled from 'styled-components'

const ModalBackground = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px 5px 0 0;
  z-index: 1000;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-top: 80px;
  }
`

const CloseButtonContainer = styled.div`
  display: flex;
  width: 80%;
  @media (min-width: 768px) {
    width: 50%;
  }
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: black;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media (min-width: 768px) {
    width: 50%;
  }
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(90vh - 70px);
  &::-webkit-scrollbar {
    display: none;
  }
`

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

const Input = styled.input`
  box-sizing: border-box;
  border: 1px solid #73767a;
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  color: black;
  transition: border-color 0.3s ease;

  &::placeholder {
    color: #73767a;
  }

  &:focus {
    outline: none;
    border-color: black;
  }

  &:not(:placeholder-shown) {
    border-color: black;
  }
`

const ErrorText = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'isSuccessful',
})`
  color: ${({ isSuccessful }) => (isSuccessful ? '#00940F' : '#FF3C00')};
  font-size: 14px;
  transition: color 0.3s ease;
  min-height: 20px;
  display: block;
`

const Label = styled.label`
  margin-bottom: 5px;
  text-align: left;
  font-size: 16px;
  width: 100%;
`

const Checkbox = styled.input`
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 0px;
`

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 10px 0;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
`

const RadioInput = styled.input`
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;

  &:checked {
    accent-color: #0066b3;
  }
`

const AgreementContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 10px 0;
`

const AgreementText = styled.span`
  font-size: 14px;
  color: #333;
  flex: 1;
`

const DetailButton = styled.button`
  color: #73767a;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;

  &:visited {
    color: #73767a;
  }
`

export {
  ModalBackground,
  Modal,
  CloseButton,
  FormContainer,
  FormItem,
  Input,
  Label,
  Checkbox,
  Text,
  CloseButtonContainer,
  ErrorText,
  RadioGroup,
  RadioLabel,
  RadioInput,
  AgreementContainer,
  AgreementText,
  DetailButton,
}
