export const MAJOR_LIST = [
  '경영학부(경영학전공)',
  '경영학부(글로벌경영학전공)',
  '경영학부(회계세무학전공)',
  '금융-빅데이터학부(금융수학과)',
  '금융-빅데이터학부(빅데이터경영전공)',
  '미디어커뮤니케이션학과',
  '관광경영학과',
  '경제학과',
  '의료산업경영학과',
  '응용통계학과',
  '사회복지학과',
  '유아교육학과',
  '심리학과',
  '패션산업학과',
  '한국어문학과',
  '영미어문학과',
  '동양어문학과',
  '유럽어문학과',
  '법학과',
  '행정학과',
  '경찰행정학과',
  '도시계획-조경학부(도시계획학전공)',
  '도시계획-조경학부(조경학전공)',
  '건축학부(건축학전공)',
  '건축공학부(건축공학전공)',
  '건축학부(실내건축학전공)',
  '설비-소방공학과',
  '화공생명배터리공학부(화공생명공학전공)',
  '화공생명배터리공학부(배터리공학전공)',
  '기계-스마트-산업공학부(기계공학전공)',
  '기계-스마트-산업공학부(산업공학전공)',
  '기계-스마트-산업공학부(스마트팩토리전공)',
  '토목환경공학과',
  '신소재공학과',
  '미래자동차공학과',
  '바이오나노학과',
  '식품생명공학과',
  '식품영양학과',
  '생명과학과',
  '물리학과',
  '화학과',
  '스마트보안전공',
  '반도체-전자공학부(전자공학전공)',
  '반도체-전자공학부(반도체공학전공)',
  '반도체-전자공학부(차세대반도체설계전공)',
  '반도체-디스플레이학과',
  '반도체설계학과',
  'AI-소프트웨어학부(소프트웨어전공)',
  'AI-소프트웨어학부(인공지능전공)',
  '컴퓨터공학부(컴퓨터공학전공)',
  '컴퓨터공학부(스마트보안전공)',
  '전기공학과',
  '스마트시티학과',
  '의공학과',
  '클라우드공학과',
  '바이오의료기기학과',
  '게임-영상학과',
  '한의예과/한의학과',
  '미술-디자인학부(회화-조소전공)',
  '미술-디자인학부(디자인전공)',
  '음악학부(성악, 기악, 작곡)',
  '체육학부(체육전공)',
  '체육학부(태권도전공)',
  '연기예술학과',
  '운동재활학과',
  '자유전공학부(자유전공)',
  '국제학부(국제자유전공)',
  '국제학부(한국학전공)',
  '의예과/의학과',
  '약학과',
  '바이오로직스학과',
  '간호학과',
  '치위생학과',
  '방사선학과',
  '물리치료학과',
  '응급구조학과',
]
