import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BackToForm from '../../assets/icons/BackToForm.svg'

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  z-index: 10;
`

const Content = styled.div`
  width: 75%;
  margin-top: 80px;
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p,
  ul {
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`

const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: black;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;

    @media (min-width: 768px) {
      width: 26px;
      height: 26px;
    }

    @media (min-width: 1024px) {
      width: 28px;
      height: 28px;
    }
  }
`

const Agreement = ({ isOpen, onClose }) => {
  return (
    <Container isOpen={isOpen}>
      <ButtonContainer>
        <BackButton onClick={onClose}>
          <img src={BackToForm} alt="back" />
        </BackButton>
      </ButtonContainer>
      <Content>
        <h1>개인정보 수집·이용 및 제공 동의서</h1>
        <p>
          해당 사이트는 학생 여러분의 권리 보장 및 학내 개선을 위한 서명운동과
          관련하여 아래와 같이 개인정보를 수집·이용하고자 합니다.
        </p>

        <h2>1. 개인정보 수집·이용 목적</h2>
        <ul>
          <li>서명운동 참가자 확인</li>
          <li>학우별 서명 참여 현황 관리</li>
          <li>가천대학교 재학 및 소속 확인</li>
          <li>서명 관련 공지 및 안내사항 전달</li>
        </ul>

        <h2>2. 수집하는 개인정보 항목</h2>
        <ul>
          <li>학과</li>
          <li>학번</li>
          <li>이름</li>
          <li>전화번호</li>
          <li>재학/휴학/졸업 여부</li>
        </ul>

        <h2>3. 개인정보의 보유 및 이용 기간</h2>
        <p>
          수집된 개인정보는 서명운동 종료 후 6개월 동안 보관되며, 이후 즉시
          폐기됩니다.
        </p>

        <h2>4. 개인정보 제 3자 제공 동의</h2>
        <ul>
          <li>
            제공 대상
            <ul>
              <li>가천대학교 40대 총학생회 &apos;청&apos;</li>
            </ul>
          </li>
          <li>
            제공 정보의 이용 목적
            <ul>
              <li>본 동의서 제1조의 내용과 동일합니다.</li>
            </ul>
          </li>
          <li>
            제공 정보의 보유 및 이용 기간
            <ul>
              <li>본 동의서 제3조의 내용과 동일합니다.</li>
            </ul>
          </li>
        </ul>
        <p>수집된 개인정보는 총학생회 외 제3자에게 제공되지 않습니다.</p>

        <h2>5. 개인정보 보호 책임자</h2>
        <ul>
          <li>이름: 노정완</li>
          <li>이메일: jeongwan.noh@gmail.com</li>
          <li>전화번호: 010-3952-0517</li>
        </ul>

        <p>※ 개인정보의 보호와 관리에 최선을 다하겠습니다. 감사합니다.</p>
      </Content>
    </Container>
  )
}

Agreement.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Agreement
