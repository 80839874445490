import styled from 'styled-components'

const ModalBackground = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

const Modal = styled.div`
  position: absolute;
  width: 70vw;
  @media (min-width: 768px) {
    width: 50vw;
  }
  @media (min-width: 1024px) {
    width: 40vw;
  }
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  gap: 1rem;
`

const ButtonText = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  text-align: center;
  color: black;
  @media (min-width: 1024px) {
    font-size: 16px;
  }
`

const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
`

const CloseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: black;
`

const Text = styled.div`
  font-size: 20px
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  @media (min-width: 1024px) {
    font-size: 30px;
  }
  color: black;
`

export {
  ModalBackground,
  Modal,
  ButtonContainer,
  CloseButtonContainer,
  CloseButton,
  Text,
  ButtonText,
  ButtonTextContainer,
}
