import React from 'react'
import styled from 'styled-components'
import MainBody from '../components/main/MainBody'
import TotalSign from '../components/totalsign/TotalSign'
import Statement from '../components/statement/Statement'
import Summary from '../components/summary/Summary'
import Footer from '../components/footer/Footer'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
`

const Main = () => {
  return (
    <Container>
      <MainBody />
      <TotalSign />
      <Statement />
      <Summary />
      <Footer />
    </Container>
  )
}

export default Main
