import axios from 'axios'

export const CountApi = async () => {
  const response = {
    count: null,
  }

  try {
    const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}/count`)

    if (result.status === 200) {
      response.count = result.data.count
    }
  } catch (error) {
    response.count = 0
  }
  return response
}
