import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 100px;
  margin-bottom: 50px;
`

const Text = styled.div`
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const TitleContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: top;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 13px;
`

const ContactTextContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const ContactText = styled.a`
  color: #ffffff;

  &:visited {
    text-decoration: none;
    color: #ffffff;
  }
`

const Contact = () => {
  const group = `40대 총학생회 '청'`

  return (
    <Container>
      <TitleContainer>
        <Text>문의</Text>
      </TitleContainer>
      <ContentContainer>
        <Text>{group}</Text>
        <ContactContainer>
          <ContactTextContainer>
            | &nbsp;
            <ContactText
              href="https://www.instagram.com/40th_gc_cheong/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @40th_gc_cheong
            </ContactText>
          </ContactTextContainer>
          <ContactTextContainer>
            | &nbsp;
            <ContactText
              href="mailto:gcu_student@gachon.ac.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              gcu_student@gachon.ac.kr
            </ContactText>
          </ContactTextContainer>
          <ContactTextContainer>
            | &nbsp;
            <ContactText
              href="tel:031-750-5445"
              target="_blank"
              rel="noopener noreferrer"
            >
              031-750-5445
            </ContactText>
          </ContactTextContainer>
        </ContactContainer>
      </ContentContainer>
    </Container>
  )
}

export default Contact
