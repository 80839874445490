export const FAQ_LIST = [
  {
    question: '본 서명 운동은 어떤 의미를 지니고 있나요?',
    answer: `본 서명 운동은 가천대학교의 학생 자치권과 기본 권리를 보장받기 위한 학우들의 목소리를 결집하고, 대학 본부에 변화와 쇄신을 요구하기 위한 중요한 수단입니다.
특히, 축제 대행사 선정 및 대학 본부의 부적절한 행태에 대한 투명한 조사와 개선을 촉구하는 학생사회의 집단적 의지를 보여주는 상징적이고 민주적인 행동입니다.`,
  },
  {
    question: '왜 우리는 서명을 해야 하나요?',
    answer: `서명은 학우들의 의견을 직접적으로 표현할 수 있는 가장 효과적인 방법입니다.
이를 통해 대학 본부에 학생사회의 불만과 요구사항을 전달하고, 문제 해결을 위한 실질적인 행동을 촉구할 수 있습니다.
2만 5천 학우들의 권리를 지키고, 더 나은 학내 환경을 만들기 위해 모든 학우들의 동참이 필요합니다.`,
  },
  {
    question: '해당 사건은 무엇인가요?',
    answer: `이번 사건은 가을 축제를 준비하는 과정에서 드러난 대학 본부와 특정 대행사 간의 유착 의혹과, 학생 자치기구 활동에 대한 본부의 과도한 개입, 폭언 및 폭행과 같은 비윤리적인 행태를 포함합니다.
축제 대행사 선정 과정이 매번 특정 인물과 연결되어 불투명하게 이루어졌다는 점, 학생들의 요구가 묵살되었다는 점에서 심각한 문제가 제기되었습니다.`,
  },
  {
    question: '위 사건의 문제점은 무엇인가요?',
    answer: `• 투명성 부족
  : 축제 대행사 선정 과정이 불투명하게 이루어졌으며, 특정 인물과의 유착 관게의혹이 지속적으로 제기되었습니다.
• 학생 자치권 침해
  : 대학 본부가 학생자치기구의 독립성과 자율성을 무시하고, 과도한 개입과 통제를 시도했습니다.
• 폭언 및 폭행
  : 일부 교직원이 학생들에게 폭언과 폭행을 가한 사건이 있었으나, 대학 본부가 이를 묵살하고 책임을 회피하려는 태도를 보였습니다.
• 권리 보장의 부재
  : 학생 구성원으로서 기본적으로 보장받아야 할 권리조차 제대로 지켜지지 않았습니다.`,
  },
  {
    question: '총학생회가 요구하는 가천대학교 구성원의 기본 원리는 무엇인가요?',
    answer: `1. 기본 권리 보장
  : 학생을 포함한 가천대학교 모든 구성원의 기본 권리를 보장할 것.
2. 투명한 집행
  : 근 7년간의 축제 대행사 선정 과정과 세부 결산, 대행사 관련자의 감사위원회를 개최할 것.
3. 문제 관계 조사 및 징계
  : 대행사와 대학 본부 간의 부적절한 관계를 철저히 조사하고, 문제가 있는 자를 징계할 것.
4. 학생 통제 금지
  : 학생을 통제 대상으로 간주하는 대학 본부의 태도를 개선할 것.
5. 학생자치기구의 자율성 존중
  : 학생들의 자치 활동을 보장하고, 자율적인 환경에서 활동할 수 있도록 지원할 것.

가천대학교의 구성원으로서 기본 권리를 보장받고, 더 나은 학내 환경을 만들기 위해 학우 여러분의 관심과 참여가 절실합니다.

지금 서명에 동참하여 여러분의 목소리를 힘껏 내어주세요.`,
  },
]
