import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../common/Button'
import ShareModal from '../modals/ShareModal'
import FormModal from '../modals/FormModal'

const Container = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`

const BtnContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @media (min-width: 768px) {
    margin-top: 3rem;
  }
  @media (min-width: 1024px) {
    margin-top: 4rem;
  }
  gap: 1rem;
  margin: 26px 0;
`

const Text = styled.div`
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 31px;
  }
  text-align: center;
  padding: 0;
  margin-bottom: 10px;
`

const Comment = styled.div`
  font-size: 8px;
  width: 100%;
  display: flex;
  color: #73767a;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
  }
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
`

const Buttons = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isShareOpen, setIsShareOpen] = useState(false)

  return (
    <Container>
      <Text>여러분의 힘이 필요합니다.</Text>
      <Text>더 나은 가천을 만들기 위해 최선을 다하겠습니다.</Text>
      <BtnContainer>
        <Button
          backgroundColor="#004E96"
          fontSize={18}
          onClick={() => setIsFormOpen(true)}
        >
          서명하기
        </Button>
        <Button
          backgroundColor="#00940F"
          fontSize={18}
          onClick={() => setIsShareOpen(true)}
        >
          공유하기
        </Button>
      </BtnContainer>
      <FormModal isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
      <ShareModal isOpen={isShareOpen} onClose={() => setIsShareOpen(false)} />
      <Comment>*입력하신 정보는 서명에만 사용되며 이후 폐기됩니다.</Comment>
    </Container>
  )
}

export default Buttons
