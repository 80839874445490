import axios from 'axios'

export const SignApi = async (name, department, sid, phone, studentType) => {
  const body = {
    name,
    department,
    sid,
    phone,
    studentType,
  }

  const response = {
    message: '',
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/sign`,
      body
    )

    if (result.status === 201) {
      response.message = result.data.message
    }
  } catch (error) {
    console.log(error)
    response.message = error.response.data.message
  }
  return response
}
