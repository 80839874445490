import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import Main from './pages/Main'

const Container = styled.div`
  width: 100vw;
  overflow-x: hidden;
  display: flex;
`

function App() {
  return (
    <Router>
      <Container>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </Container>
    </Router>
  )
}

export default App
