import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ButtonContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' &&
    prop !== 'color' &&
    prop !== 'fontSize' &&
    prop !== 'btnWidth',
})`
  background-color: ${(props) => props.backgroundColor || 'black'};
  color: ${(props) => props.color || 'white'};
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  width: ${(props) => (props.btnWidth ? `${props.btnWidth}` : 'auto')};
  font-size:${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  width: ${({ btnWidth }) => (btnWidth ? `${btnWidth}` : 'auto')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
    padding: 15px 30px;
  }
`

const Button = ({
  children,
  backgroundColor,
  color,
  onClick,
  fontSize,
  btnWidth,
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      backgroundColor={backgroundColor}
      color={color}
      fontSize={fontSize}
      btnWidth={btnWidth}
    >
      {children}
    </ButtonContainer>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  fontSize: PropTypes.number,
  btnWidth: PropTypes.string,
}

export default Button
