import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'hoverEffect',
})`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
  width: ${(props) => props.size || 'auto'};
  height: ${(props) => props.size || 'auto'};
  outline: none;

  &:hover {
    transform: ${(props) => (props.hoverEffect ? 'scale(1.05)' : 'none')};
    transition: transform 0.2s;
  }
`

const StyledImage = styled.img.withConfig({
  shouldForwardProp: (prop) => prop !== 'objectFit',
})`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.objectFit || 'contain'};
`

const ImageButton = ({ src, alt, onClick, size, hoverEffect }) => (
  <StyledButton size={size} hoverEffect={hoverEffect} onClick={onClick}>
    <StyledImage src={src} alt={alt} />
  </StyledButton>
)

ImageButton.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  hoverEffect: PropTypes.bool,
}

export default ImageButton
