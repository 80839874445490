import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`

const Statement = () => {
  return (
    <Container id="statement">
      <Header />
      <Body />
      <Footer />
    </Container>
  )
}

export default Statement
