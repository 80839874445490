import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  ModalBackground,
  Modal,
  ButtonContainer,
  CloseButtonContainer,
  CloseButton,
  Text,
  ButtonTextContainer,
  ButtonText,
} from '../../styles/Modal/ShareModalStyles'
import kakaoIcon from '../../assets/icons/kakao.svg'
import twitterIcon from '../../assets/icons/x.svg'
import urlIcon from '../../assets/icons/url.svg'
import ImageButton from '../common/ImageButton'
import closeIcon from '../../assets/icons/back.svg'

const ShareModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null)

  const kakaoInit = () => {
    if (window.Kakao) {
      window.Kakao.cleanup()
      window.Kakao.init(process.env.REACT_APP_KAKAO_KEY)
    } else {
      console.error('Kakao SDK not loaded')
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      if (modalRef.current) {
        modalRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      kakaoInit()
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const handleKakaoShare = async () => {
    try {
      const kakao = window.Kakao
      if (!kakao || !kakao.isInitialized()) {
        throw new Error('Kakao SDK not initialized')
      }

      const params = {
        objectType: 'text',
        text: '가천대학교 등록금 집행 투명성 확보 및 학생 기본 권리 보장을 위한 온라인 서명 운동입니다.',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      }

      await kakao.Share.sendDefault(params)
      onClose()
    } catch (error) {
      console.error('Kakao share error:', error)
      alert(
        '카카오톡 공유에 실패했습니다. 카카오톡 앱이 설치되어 있는지 확인해주세요.'
      )
    }
  }

  const handleTwitterShare = () => {
    const text =
      '가천대학교 등록금 집행 투명성 확보 및 학생 기본 권리 보장을 위한 온라인 서명 운동입니다.'
    const url = window.location.href
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`
    window.open(twitterUrl, '_blank')
    onClose()
  }

  const handleCopyLink = () => {
    const url = window.location.href
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('링크가 복사되었습니다.')
        onClose()
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <>
      <ModalBackground isOpen={isOpen} />
      <Modal onClick={(e) => e.stopPropagation()} ref={modalRef}>
        <CloseButtonContainer>
          <Text>공유하기</Text>
          <CloseButton onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </CloseButtonContainer>
        <ButtonContainer>
          <ButtonTextContainer>
            <ImageButton
              src={kakaoIcon}
              onClick={handleKakaoShare}
              alt="kakao"
            />
            <ButtonText>카카오톡</ButtonText>
          </ButtonTextContainer>
          <ButtonTextContainer>
            <ImageButton
              src={twitterIcon}
              onClick={handleTwitterShare}
              alt="x"
            />
            <ButtonText>X</ButtonText>
          </ButtonTextContainer>
          <ButtonTextContainer>
            <ImageButton src={urlIcon} onClick={handleCopyLink} alt="link" />
            <ButtonText>링크복사</ButtonText>
          </ButtonTextContainer>
        </ButtonContainer>
      </Modal>
    </>,
    document.body
  )
}

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ShareModal
