const isValidSid = (sid) => typeof sid === 'string' && /^\d{9}$/.test(sid)

const isValidName = (name) =>
  typeof name === 'string' && name.trim().length >= 2

const isValidAgree = (agree) => agree === true

const isValidPhone = (phone) =>
  typeof phone === 'string' && /^\d{3}-\d{3,4}-\d{4}$/.test(phone)

export { isValidSid, isValidName, isValidAgree, isValidPhone }
