import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Button from '../common/Button'
import Dropdown from './Dropdown'
import Agreement from './Agreement'
import { SignApi } from '../../apis/signAPI'
import {
  isValidAgree,
  isValidName,
  isValidSid,
  isValidPhone,
} from '../../utils/validation'

import {
  ModalBackground,
  Modal,
  CloseButton,
  FormContainer,
  FormItem,
  Input,
  Label,
  Checkbox,
  Text,
  CloseButtonContainer,
  ErrorText,
  RadioGroup,
  RadioLabel,
  RadioInput,
  AgreementContainer,
  AgreementText,
  DetailButton,
} from '../../styles/Modal/FormModalStyles'
import { MAJOR_LIST } from '../../constants/department'
import closeIcon from '../../assets/icons/back.svg'

const FormModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null)
  const [showAgreement, setShowAgreement] = useState(false)
  const [submitMessage, setSubmitMessage] = useState({
    message: '',
    success: false,
  })
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    sid: '',
    phone: '',
    studentType: '재학생',
    agree: false,
  })

  const [errors, setErrors] = useState({
    name: '',
    sid: '',
    phone: '',
    agree: '',
  })

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }))
    validateField(name, type === 'checkbox' ? checked : value)
  }

  const handleDetailClick = () => {
    setShowAgreement(true)
  }

  const handleBackToForm = () => {
    setShowAgreement(false)
  }

  const validateField = (name, value) => {
    let errorMessage = ''
    if (value !== '') {
      switch (name) {
        case 'name':
          if (!isValidName(value))
            errorMessage = '이름은 두 글자 이상이어야 합니다.'
          break
        case 'sid':
          if (!isValidSid(value))
            errorMessage = '올바른 학번을 입력해주세요 (9자리 숫자).'
          break
        case 'phone':
          if (!isValidPhone(value))
            errorMessage = '올바른 전화번호 형식을 입력해주세요.'
          break
        case 'agree':
          if (!isValidAgree(value))
            errorMessage = '개인정보 제공에 동의해주세요.'
          break
        default:
          break
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isFormValid) {
      try {
        const response = await SignApi(
          formData.name,
          formData.department,
          formData.sid,
          formData.phone,
          formData.studentType
        )
        if (response.message === '정상적으로 서명이 완료되었습니다.') {
          setSubmitMessage({
            message: response.message,
            success: true,
          })

          setTimeout(() => {
            onClose()
          }, 5000)
        } else {
          setSubmitMessage({
            message: response.message,
            success: false,
          })
        }
      } catch (error) {
        console.error('서명 중 오류가 발생했습니다.', error)
      }
    } else {
      alert('모든 항목을 정확하게 입력하고 동의해주세요.')
    }
  }

  const isFormValid =
    Object.values(errors).every((error) => error === '') &&
    Object.values(formData).every((value) => value !== '') &&
    formData.agree

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <ModalBackground isOpen={isOpen} onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()} ref={modalRef}>
        {showAgreement ? (
          <></>
        ) : (
          <CloseButtonContainer>
            <Text>서명하기</Text>
            <CloseButton onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </CloseButton>
          </CloseButtonContainer>
        )}
        {showAgreement ? (
          <Agreement isOpen={showAgreement} onClose={handleBackToForm} />
        ) : (
          <FormContainer onSubmit={handleSubmit}>
            <FormItem>
              <Label htmlFor="name">이름</Label>
              <Input
                placeholder="이름"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <ErrorText>{errors.name}</ErrorText>
            </FormItem>
            <FormItem>
              <Label htmlFor="department">학과</Label>
              <Dropdown
                options={MAJOR_LIST}
                value={formData.department}
                onChange={handleChange}
                placeholder="학과"
              />
              <ErrorText>{errors.department}</ErrorText>
            </FormItem>
            <FormItem>
              <Label htmlFor="sid">학번</Label>
              <Input
                placeholder="000000000"
                type="text"
                id="sid"
                name="sid"
                value={formData.sid}
                onChange={handleChange}
              />
              <ErrorText>{errors.sid}</ErrorText>
            </FormItem>
            <FormItem>
              <Label htmlFor="phone">전화번호</Label>
              <Input
                placeholder="010-0000-0000"
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <ErrorText>{errors.phone}</ErrorText>
            </FormItem>
            <FormItem>
              <Label>재학 구분</Label>
              <RadioGroup>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="studentType"
                    value="재학생"
                    checked={formData.studentType === '재학생'}
                    onChange={handleChange}
                  />
                  재학생
                </RadioLabel>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="studentType"
                    value="휴학/졸업생"
                    checked={formData.studentType === '휴학/졸업생'}
                    onChange={handleChange}
                  />
                  휴학/졸업생
                </RadioLabel>
              </RadioGroup>
            </FormItem>

            <AgreementContainer>
              <Checkbox
                type="checkbox"
                id="agree"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
              />
              <AgreementText>
                서명을 위한 개인정보이용 및 제공에 동의합니다.
              </AgreementText>
              <DetailButton type="button" onClick={handleDetailClick}>
                상세 내용 확인
              </DetailButton>
            </AgreementContainer>
            <ErrorText>{errors.agree}</ErrorText>

            <Button
              type="submit"
              backgroundColor="#004E96"
              btnWidth="100%"
              disabled={!isFormValid}
              style={{ fontSize: '18px' }}
            >
              서명 완료
            </Button>
            <ErrorText isSuccessful={submitMessage.success}>
              {submitMessage.message}
            </ErrorText>
          </FormContainer>
        )}
      </Modal>
    </ModalBackground>,
    document.body
  )
}

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FormModal
