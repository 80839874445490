import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { createGlobalStyle } from 'styled-components'
import GlobalFont from './styles/fonts/fonts'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: #0A0F16;
    font-family: 'Pretendard', sans-serif;
  }

  #root {
    display: flex;
    color: white;
  }
`

const rootElement = document.getElementById('root')

const root = ReactDOM.createRoot(rootElement)

root.render(
  <ThemeProvider theme={theme}>
    <GlobalFont />
    <GlobalStyle />
    <App />
  </ThemeProvider>
)
