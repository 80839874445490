import React from 'react'
import styled from 'styled-components'
import InstaArrow from '../../assets/icons/InstaArrow.svg'

const Container = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`

const Group = styled.div`
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (min-width: 1024px) {
    font-size: 33px;
  }
  text-align: center;
  padding: 0;
  margin: 0;
`

const Statement = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 30px;
  }
  @media (min-width: 1024px) {
    font-size: 40px;
  }
  text-align: center;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 12px;
`

const InstaContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  gap: 15px;
  text-decoration: none;
  color: #73767a;

  &:visited {
    text-decoration: none;
    color: #73767a;
  }
`

const InstaImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`

const Insta = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #73767a;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

const Header = () => {
  const groupText = `40대 총학생회 '청'`
  return (
    <Container>
      <Group>{groupText}</Group>
      <Statement>성명문</Statement>
      <InstaContainer
        href="https://www.instagram.com/p/DCwFLxvhCKn/?img_index=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Insta>인스타그램 원문 보기</Insta>
        <InstaImg src={InstaArrow} alt="Instagram Arrow" />
      </InstaContainer>
    </Container>
  )
}

export default Header
