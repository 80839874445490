import React from 'react'
import styled from 'styled-components'
import Buttons from './Buttons'
import FAQ from './FAQ'

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: linear-gradient(to bottom, #0a0f16 19%, #1d4e87 100%);
`

const Summary = () => {
  return (
    <Container>
      <Buttons />
      <FAQ />
    </Container>
  )
}

export default Summary
