import React from 'react'
import styled from 'styled-components'
import BackgroundImg from './BackgroundImg'
import Content from './Content'
import Buttons from './Buttons'
import DownArrow from '../../assets/icons/DownArrow.svg'
import { HashLink } from 'react-router-hash-link'

const BottomContainer = styled.div`
  width: 80vw;
  margin-top: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

const BottomShowMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 0.6rem;
`

const MainBody = () => {
  return (
    <BackgroundImg>
      <Content />
      <Buttons />
      <BottomContainer>
        <BottomShowMore>
          <HashLink
            style={{
              textDecoration: 'none',
              color: 'white',
            }}
            smooth
            to="#statement"
          >
            성명문 확인하기
          </HashLink>
          <img src={DownArrow} alt="성명문으로 이동" />
        </BottomShowMore>
        <BottomShowMore>
          <HashLink
            style={{
              textDecoration: 'none',
              color: 'white',
            }}
            smooth
            to="#faqsummary"
          >
            FAQ & 요약 확인하기
          </HashLink>
          <img src={DownArrow} alt="FAQ로 이동" />
        </BottomShowMore>
      </BottomContainer>
    </BackgroundImg>
  )
}

export default MainBody
